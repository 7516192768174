import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import TokenManager from "../../Apis/TokenManager";

const HeaderTopbar2 = () => {
  const [token, setToken] = useState(null);
  useEffect(() => {
   const accessToken = TokenManager.getAccessToken();
   if(accessToken){
    setToken(accessToken);
   }else{
    setToken(null);
   }
  }, []);
  return (
    <div className="header-topbar">
      <div className="container">
        <div className="row">
          <div className="col col-md-6 col-sm-7 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <i className="fi ti-location-pin"></i>Wijsthoek 302, 5403RG Uden
                </li>
                <li>
                  <i className="fi flaticon-envelope"></i>{" "}
                  <a style={{color:"white"}} href="mailto:info@alsharq.nl">info@alsharq.nl</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-md-6 col-sm-5 col-12">
            <div className="contact-info">
              {token ? (
                <ul>
                  <li>
                    <Link to="/home2">
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/home2">
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/home2">
                      <i className="ti-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="theme-btn-s2" to="/doneren">
                      Doneer
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to="/login">Inloggen</Link>
                  </li>
                  <li>
                    <Link to="/signup">Aanmelden</Link>
                  </li>
                  <li>
                    <Link className="theme-btn-s2" to="/doneren">
                      Doneer Nu
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar2;
