import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Auth from '../../Apis/Auth';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoginPage = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({
        email: '',
        wachtwoord: '',
        onthouden: false,
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({ ...value, onthouden: !value.onthouden });
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setLoading(true);
            const response = await Auth.login({
                email: value.email,
                wachtwoord: value.wachtwoord
            });
            console.log("login reactie", response);

            if (response.success) {
                toast.success("Welkom!");
                validator.hideMessages();
                navigate('/');
            } else {
                validator.showMessages();
                toast.error(response.error);
            }
            setLoading(false);
        } else {
            validator.showMessages();
            toast.error('Lege velden zijn niet toegestaan!');
        }
    };

    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Inloggen</h2>
                <p>Log in op je account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Wachtwoord"
                                value={value.wachtwoord}
                                variant="outlined"
                                name="wachtwoord"
                                type="password"
                                label="Wachtwoord"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={changeHandler}
                                onChange={changeHandler}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formAction">
                                <FormControlLabel
                                    control={<Checkbox checked={value.onthouden} onChange={rememberHandler} />}
                                    label="Onthoud mij"
                                />
                                <Link to="/wachtwoord-vergeten">Wachtwoord vergeten?</Link>
                            </Grid>
                            <Grid className="formFooter">
                                <Button disabled={loading} fullWidth className="cBtnTheme" type="submit">
                                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Inloggen"}
                                </Button>
                            </Grid>
                            <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="instagram"><i className="fa fa-instagram"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid>
                            <p className="noteHelp">Heb je nog geen account? <Link to="/signup">Maak een gratis account aan</Link></p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
