/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import SessionApi from '../../Apis/SessionApi';
const Return = () => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get("session_id");
    
        const fetchSessionStatus = async () => {
          const response = await SessionApi.getSessionStatus(sessionId);
          // console.log("session_id", sessionId);
          setStatus(response?.data.status);
        };
        if (sessionId) {
          fetchSessionStatus();
        }
      }, []); 

      if (status === "open") {
        window.location.href="/doneren"
      }
      if (status === "complete") {
        return (
          <section id="success" style={styles.container}>
            <p style={styles.text}>
              We appreciate your donation! 
              If you have any questions, please email{" "}
              <a href="mailto:info@alsharq.nl">info@alsharq.nl</a>.
            </p>
            <button
              style={styles.button}
              onClick={() => (window.location.href = "/")}
            >
              Back
            </button>
          </section>
        );
      } else {
        return <p>Loading...</p>;
      }
}

export default Return
const styles= {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      textAlign: "center",
    },
    text: {
      fontSize: "18px",
      margin: "20px 0",
    },
    button: {
      padding: "10px 20px",
      fontSize: "16px",
      backgroundColor: "#4CAF50",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    errorText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      fontSize: "18px",
      color: "red",
    },
  };