import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom';

class PaymentInfo extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        adress: '',
        card: '',
        expire: '',
        cvc: '',
        amount: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            phone,
            adress,
            card,
            expire, 
            cvc, 
            amount, 
            error } = this.state;

        if (name === '') {
            error.name = "Vul uw naam in alstublieft";
        }
        if (email === '') {
            error.email = "Vul uw e-mail in alstublieft";
        }
        if (phone === '') {
            error.phone = "Vul uw telefoonnummer in alstublieft";
        }
        if (adress === '') {
            error.adress = "Vul uw adres in alstublieft";
        }
        if (card === '') {
            error.card = "Vul uw kaartnummer in alstublieft";
        }
        if (expire === '') {
            error.expire = "Vul de vervaldatum in alstublieft";
        }
        if (cvc === '') {
            error.cvc = "Vul de CVC-code in alstublieft";
        }
        if (amount === '') {
            error.amount = "Vul het bedrag in alstublieft";
        }

        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.email === '' && error.email === '' && error.adress === '' && error.phone === '' && error.card === '' && error.expire === '' && error.cvc === '' && error.amount === '') {
            this.setState({
                name: '',
                email: '',
                phone: '',
                card: '',
                expire: '',
                adress: '',
                cvc: '',
                amount: '',
                error: {}
            })
        }
    }

    render() {
        const { name,
            email,
            phone,
            adress,
            card,
            expire,
            cvc,
            amount,
            error } = this.state;

        return (
            <div className="payment-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="payment-text">
                                <span>Doneer Nu</span>
                                <h2>Ondersteun Onze Missie: Maak Vandaag Het Verschil</h2>
                                <p>Bij Alsharq helpt uw vrijgevigheid ons om duurzame, positieve veranderingen te creëren. Elke donatie, ongeacht de grootte, helpt ons essentiële middelen te bieden, impactvolle programma's te ondersteunen en gemeenschappen in nood te helpen.</p>
                                <Link to={'/doneren'} className="theme-btn btn-donate" >Doneer Nu</Link>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}
export default PaymentInfo;
