import axios from "axios"
import TokenManager from "./TokenManager"

const SWAGGER_BASE_URL = "https://app-alsharq-dev.azurewebsites.net/api/Checkout"

const SessionApi={
    getSessionStatus: async (id) => {
        try {
          const response = await axios.get(SWAGGER_BASE_URL+"/getsession?session_id="+id);
          return { success: true, data: response.data };
        } catch (error) {
          return { success: false, error: error };
        }
      },
      handleCheckOut: async (payload) => {
        try {
          const response = await axios.post(SWAGGER_BASE_URL+"/createsession?amount="+payload);
          return { success: true, data: response.data };
        } catch (error) {
          return { success: false, error: error };
        }
      },
}
export default SessionApi